import Swiper from 'swiper'

window.document.addEventListener('DOMContentLoaded', function () {
  document.querySelector('.pickup').style.display = 'block'
  /* eslint-disable no-unused-vars */
  var mySwiper = new Swiper('.pickup-swiper', {
    speed: 700,
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      bulletActiveClass: 'swiper-pagination-bullet-active swiper-pagination-bullet-active_white',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      480: {
        slidesPerView: 1.75,
      },
      640: {
        slidesPerView: 2.3,
      },
    },
  })
  /* eslint-enable no-unused-vars */
})
